import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import { FaQuestionCircle } from "@react-icons/all-files/fa/FaQuestionCircle";
import PageTitle from "../components/common/page-title";

const faqContent = [
  {
    question: "What qualities do I need to be a yoga teacher?",
    answer:
      "Everyone is welcome to join our multi-style yoga teacher training courses whether you have knowledge or practice of yoga or not. You do not require any previous experience of yoga to apply to the multi-style.",
  },
  {
    question: "Which kind of clothes should I bring?",
    answer:
      "Loose cotton clothes or Yoga Attires are best suited rest its purely a personal choice",
  },
  {
    question:
      "Are the yoga teacher training courses internationally certified?",
    answer:
      "Yes, Multi Style Yoga School is a registered yoga school with Yoga Alliance (USA) and we provide internationally recognized yoga teacher training certifications in India. All our multi-style yoga teacher training participants, who have successfully completed the course, can register with Yoga Alliance®.",
  },
  {
    question: "What safety precautions do I need to follow?",
    answer:
      "While it is mostly safe to travel alone to Bali but do ensure you: Take precautionary measures and dress appropriately as per local culture. Collect all information about the place where you are booked for your yoga retreats or yoga teacher training course. Have on hand the Mobile/WhatsApp number and email of the yoga school. It is advisable to arrange for a pick-up with the yoga school that you are booked with, as thats safer or book only through reliable travel agents.",
  },
  {
    question: "What if I don't intend to be a yoga teacher?",
    answer:
      "Forget about it! Our Multi-style Yoga Teachers Training is perfect for the individuals who need to go deeper further into the exploration of yoga, and to enhance their own particular practice. It’s likewise extraordinary for students who essentially need to have a transformational change and to be a piece of our flourishing Yoga Community.",
  },
  {
    question: "Can I take this training even if i'm pregnant?",
    answer:
      "We have had numerous pregnant students in our programs. It all depends on how you feel. All students ought to first check with their specialist to ensure they are clear to take this training.",
  },
  {
    question: "Could I smoke or take drugs amid the course?",
    answer:
      "Smoking and unlawful medications are entirely denied throughout the course. You will be expelled from the course immediately without any refunds if you are known to be involved in such deeds.",
  },
  {
    question: "Do I need to pay my booking in advance?",
    answer:
      "The booking can be confirmed at AAYAA YOGA by paying a deposit (normally 30% of the total fees) which varies depending on the program, for each individual booking. (All deposits are non-refundable and non-transferable.) The balance payment is due one on.",
  },
  {
    question: "Do you have access to internet?",
    answer:
      "We have WiFi internet in the campus, ask for the codes at the reception. We however discourage people to spend a lot of time on the computer.",
  },
  {
    question: "Do I need to bring my own yoga mat?",
    answer:
      "A yoga mat is just not a mat but your personal asset. It is recommended that you bring your own yoga mat (for hygiene purpose) else you may buy from our collection . Else you can use the mats that are there for all",
  },
  {
    question: "Which other items do you suggest bringing?",
    answer:
      "Most of the following items can be purchased locally and cheaper than in the West, but you may want to have them with you if you don’t want to spend your first couple of days shopping. We advice you to have some funds on your arrival, which you can purchase at your airport of departure, Torch / flashlight : If you are sensitive sleeper, it is always recommended. Mosquito repellent: There is a local brand called Odomos available widely. You may want to bring your own with you but this works best. Sunscreen: You will need to take care against the strong sun, especially when arriving from winter.",
  },
];

const FAQ = ({ siteTitle }) => (
  <Layout>
    <Seo title="FAQ" />

    <div className="pt-4">
      <PageTitle name="Got Some Questions" />
      <p className="text-base leading-6 text-gray-600 pb-6">
        Generally before you start your journey as a Yogi you must go through
        these useful and important information from the frequently asked
        questions concerning attending the yoga retreats, yoga holidays and
        vacations in a yoga retreat.
      </p>
      <p className="text-base leading-6 text-gray-600 pb-6">
        In this page you will find answers to the most commonly asked questions.
        Also some important information which you should have before you come to
        attend the yoga retreat at AAYAA we encourage our guest to go through
        this at least once before booking your tickets. If you still have
        questions regarding any issues please write to us.
      </p>
      <ul className="items-start  flex flex-col lg:flex-row lg:flex-wrap lg:gap-8 justify-between">
        {faqContent.map((item) => (
          <li className="lg:w-2/5 pb-4" key={item.question}>
            <p className="text-lg font-medium leading-6 text-gray-900 flex flex-row content-center">
              <i className="block text-orange-600 mr-2 place-self-center">
                <FaQuestionCircle />
              </i>{" "}
              <span className="inline-block">{item.question}</span>
            </p>
            <p className="mt-2">
              <span className="inline-block text-base leading-6 text-gray-500">
                {item.answer}
              </span>
            </p>
          </li>
        ))}
      </ul>
      <div className="">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block text-gray-600">Didn't find the answer?</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
              >
                Speak to us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default FAQ;
