import * as React from "react";
import { Link } from "gatsby";

const Thanks = () => {
  return (
    <main className="h-screen w-auto flex flex-col justify-center items-center">
      <div className="flex items-center justify-center w-auto h-screen bg-gradient-to-r from-grey-600 to-blue-400">
        <div className="px-40 py-20 bg-white rounded-md shadow-xl">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-orange-600 text-xl">Thank you!</h1>

            <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
              Your request is recorded.
            </h6>

            <p className="mb-8 text-center text-gray-500 md:text-lg">
              Our replies may land in your spam. Please do check your spam. We
              reply within a maximum of 12 hours. We will get in touch with you
              shortly. Thank you
            </p>

            <Link
              to="/"
              className="px-6 py-2 text-sm font-semibold text-orange-800 bg-orange-100"
            >
              Go home
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Thanks;
