import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FaRegDotCircle } from "@react-icons/all-files/fa/FaRegDotCircle";

const AerialYoga = () => (
  <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md ">
    <StaticImage
      className="rounded-t-lg"
      src="../../../images/course-details/AERIAL-YOGA.jpg"
      alt="AERIAL YOGA"
    />

    <div className="p-5">
      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
        AERIAL YOGA
      </h5>

      <ul className="list-disc list-inside mb-3 font-normal text-gray-700">
        {data.map((item) => (
          <li className="flex flex-row mb-1 text-sm" key={item.text}>
            <div className="mr-3 my-auto">
              <FaRegDotCircle className="h-3 w-3 text-orange-600" />
            </div>
            <div>{item.text}</div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const data = [
  {
    text: "Experiment with anti-gravity!",
  },
  {
    text: "Take use of all the advantages of inversion stances.",
  },
  {
    text: "Increase your flexibility, strength, core stability, and coordination.",
  },
  {
    text: "Learn how to create therapeutic treatment sequences.",
  },
  {
    text: "Push yourself to your limits and fly!",
  },
];

export default AerialYoga;
