import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";

import Gallery from "@browniebroke/gatsby-image-gallery";

const GalleryPage = ({ data }) => {
  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    caption: `Aayaa Yoga Gallery #${index + 1}`,
  }));

  return (
    <Layout>
      <Seo title="Gallery" />
      <div className="pt-2">
        <PageTitle name="Gallery Bali - Puri Suksma" />
        <div className="">
          <Gallery images={images} />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ImagesForYogaHolidayGalleryBaliPuri {
    images: allFile(
      filter: { relativeDirectory: { eq: "yoga-holidays-bali" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 305
              height: 210
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default GalleryPage;
