import * as React from "react";

import { GrYoga } from "@react-icons/all-files/gr/GrYoga";
import { StaticImage } from "gatsby-plugin-image";

const WhyAayaa = () => {
  return (
    <div className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-4 sm:px-8 lg:px-16 mx-auto">
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-10 py-4 my-12">
        <div className="flex w-full justify-end">
          <div className="h-full w-full p-4">
            <StaticImage
              className="w-full rounded-md lg:h-full shadow-lg"
              src="../../images/others/AayaaYogaInternational-47.jpg"
              alt="Why Aayaa"
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center ml-auto w-full">
          <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-orange-600">
            Why "AAYAA"? Why choose us
          </h3>
          <p className="my-2 text-gray-700">
            You can explore the features that we provide with fun and have their
            own functions each feature.
          </p>
          <ul className="text-gray-600 self-start list-inside">
            {whyUs.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const WhyBali = () => {
  return (
    <div className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-4 sm:px-8 lg:px-16 mx-auto">
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-10 py-4 my-12">
        <div className="flex w-full justify-end lg:hidden">
          <div className="h-full w-full p-4">
            <StaticImage
              className="w-full rounded-md lg:h-full shadow-lg"
              src="../../images/others/home-3.jpg"
              alt="Why Aayaa Bali"
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center ml-auto w-full">
          <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-orange-600">
            Why AAYAA "BALI"? - Your Home in God's Own country
          </h3>
          <p className="my-2 text-gray-700">
            You can explore the features that we provide with fun and have their
            own functions each feature.
          </p>
          <ul className="text-gray-600 self-start list-inside">
            {whyBali.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-full justify-end hidden lg:block">
          <div className="h-full w-full p-4">
            <StaticImage
              className="w-full rounded-md lg:h-full shadow-lg"
              src="../../images/others/home-3.jpg"
              alt="Why Aayaa Bali"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const WhyRishikesh = () => {
  return (
    <div className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-4 sm:px-8 lg:px-16 mx-auto">
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-10 py-4 my-12">
        <div className="flex w-full justify-end lg:hidden">
          <div className="h-full w-full p-4">
            <StaticImage
              className="w-full rounded-md lg:h-full shadow-lg"
              src="../../images/others/rishikesh_temple.jpg"
              alt="Why Aayaa Rishikesh"
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center ml-auto w-full">
          <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-orange-600">
            Why AAYAA "RISHIKESH"? - The Yoga Capital
          </h3>
          <p className="my-2 text-gray-700">
            You can explore the features that we provide with fun and have their
            own functions each feature.
          </p>
          <ul className="text-gray-600 self-start list-inside">
            {whyRishikesh.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-full justify-end hidden lg:block">
          <div className="h-full w-full p-4">
            <StaticImage
              className="w-full rounded-md lg:h-full shadow-lg"
              src="../../images/others/rishikesh_temple.jpg"
              alt="Why Aayaa Rishikesh"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const whyUs = [
  {
    text: "At AAYAA our vision to make the world a better and safe place for people from all walks of life.We believe in the philosophy of soul where Age, Location and Religion are no boundaries.",
  },
  {
    text: "We achieve our vision by teaching simple, traditional systematic and scientific techniques of yoga for everyone.We don't work only on your physical body instead we make you conscious of Your four 'Interior Empires': 'Mind-set,  Heart-set, Health-set and Soul-set.'",
  },
  {
    text: " We believe that balance only comes when all areas are touched so we believe in your holistic learning through the field of YOG and take you on a path to witness and learn how to control and still the mind, recognising a detached witness-consciousness unaffected by the mind (Chitta) and mundane suffering by adapting modern techniques of Yoga Its for all, newcomers and more experienced practitioners, thus promoting the inclusion and participation of people of diverse identities, including those of all races, ethnicities, cultures, sexual orientations, gender identities, classes, religions, and abilities with the aspiration that all feel welcome.",
  },
  {
    text: "Its for all, newcomers and more experienced practitioners, thus promoting the inclusion and participation of people of diverse identities, including those of all races, ethnicities, cultures, sexual orientations, gender identities, classes, religions, and abilities with the aspiration that all feel welcome.",
  },
];

const whyBali = [
  {
    text: "As we know Yoga started in India hundreds of years ago, and Bali is known as 'the Gods' Islands.' Bali is known as the region's favorite yogi retreat due to its spiritual and physical beauty, Bali is an ideal resort for learning and practicing yoga.",
  },
  {
    text: "Bali's breathtaking sights allow visitors to reflect and meditate profoundly in the fresh environment of the island. The land offers a diverse marine species biodiversity, as well as a multitude of sites to surf and explore beaches and oceans.",
  },
  {
    text: "We believe that doing yoga on a daily basis may improve people's physical, mental, and spiritual wellbeing. The resulting ripple effect will spread throughout your surrounding people, community, and workforce, positively influencing everyone.",
  },
  {
    text: "Aayaa Yoga Teachers Training Center Bali invites everyone to join us on the yogic path to a healthy and serene existence. We make certain that our location remains secure and appealing to you.",
  },
];

const whyRishikesh = [
  {
    text: "Rishikesh is one of the holiest places for Hindus. That's why sages and saints who master the yogic arts have been visiting the city for thousands of years. ",
  },
  {
    text: "Rishikesh is a city in India's northern state of Uttarakhand, in the Himalayan foothills beside the Ganges River. The river is considered holy, and the city is renowned as a center for studying yoga and meditation.",
  },
  {
    text: "We believe that doing yoga on a daily basis may improve people's physical, mental, and spiritual wellbeing. The resulting ripple effect will spread throughout your surrounding people, community, and workforce, positively influencing everyone.",
  },
  {
    text: "Aayaa Yoga Teachers Training Center Rishikesh invites everyone to join us on the yogic path to a healthy and serene existence. We make certain that our location remains secure and appealing to you.",
  },
];

export { WhyAayaa, WhyBali, WhyRishikesh };
