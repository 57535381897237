import * as React from "react";

import { Link } from "gatsby";

import { FaChalkboardTeacher } from "@react-icons/all-files/fa/FaChalkboardTeacher";
import { FaCalendarAlt } from "@react-icons/all-files/fa/FaCalendarAlt";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaWind } from "@react-icons/all-files/fa/FaWind";

const Discover = () => {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-xl text-orange-600 font-semibold tracking-wide uppercase">
            AAYAA YOGA
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Discover your spiritual journey with us
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            AAYAA is the ultimate destination for all your queries - its that
            one center where you get answers for your heart and soul
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                  <FaEye className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  Aayaa Vision
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                <ul className="list-disc ml-4">
                  <li>
                    Through yoga we walk a path that creates balance, harmony
                    and bliss inside us We not only understand the world in a
                    different way rather we breathe through every moment with a
                    new perspective
                  </li>
                  <li>
                    Life is a journey , the goal of which is self discovery and
                    consciousness of one's soul , its just that we need to chose
                    a road to that - AAYAA Yoga is that road for one and all
                    Where we practice yoga in a traditional way with a blend of
                    modernization.
                  </li>
                  <li className="font-medium tracking-wide">
                    Your journey to recognize and work on your “Interior
                    Empires”: “Mind-set, Heartset, Healthset and Soulset.”
                    begins at AAYAA Yoga
                  </li>
                </ul>
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                  <FaWind className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  Aayaa Belief & Values
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                <ul className="list-disc ml-4">
                  <li>
                    We believe that if each person adopted just 10% of the yogic
                    practices, the world would be a different place.
                  </li>
                  <li>
                    Yogic philosophy is an essential component of this course,
                    providing students with an overview of the various aspects
                    and concepts of yogic life.
                  </li>
                  <li>
                    The core explanation of AAYAA is to unite mind, body, and
                    soul, and in order to achieve a peaceful lifestyle.
                  </li>
                </ul>
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                  <FaCalendarAlt className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  Course Calendar
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Hundreds of people have had their lives transformed by school.
                If you want to alter yourself for a happier life and share it
                with others, you can start your yogic journey here.
                <span className="text-orange-700 ml-2">
                  <Link to="/calendar-bali">...know more bali</Link>
                </span>
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                  <FaChalkboardTeacher className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                  Expert Instructors
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Whether you want to improve your practice or start a career in
                Yoga, at AAYAA Yoga we respect your desire to learn and promise
                you of a highly gratifying course taught by the best teachers,
                devised by numerous yogic brains, and seasoned with time and
                experience.
                <span className="text-orange-700 ml-2">
                  <Link to="/teachers">...know more</Link>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Discover;
