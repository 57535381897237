import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MainPageHeader = () => {
  return (
    <div className="container px-2 py-4 mx-auto lg:flex lg:h-128 lg:py-16 ">
      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
        <div className="max-w-lg">
          <h2 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 mb-6 text-orange-600">
            Welcome To Aayaa Yoga
          </h2>
          <p className="mt-4 text-gray-600">
            Welcome to the world of AAYAA, Its all in the name AAYAA- "All About
            Yoga And Ayurveda."
          </p>
          <p className="mt-4 text-gray-600">
            AAYAA is the ultimate destination for all your queries - its that
            one center where you get answers for your heart and soul.
          </p>
          <p className="mt-4 text-gray-600">
            We welcome you to be a part of the community of AAYAA - The Yogis
            who will change the world and prepare our tribe for any pandemic.
          </p>
          {/* <div className="mt-6 mb-4">
            <Link
              href="/bookings"
              className="shadow-lg inline-block px-3 py-2 font-semibold text-center text-white transition-colors duration-200 transform bg-orange-500 rounded-md hover:bg-orange-400"
            >
              Enquire Now
            </Link>
          </div> */}
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-2 lg:h-96 lg:w-1/2 shadow-2xl">
        <StaticImage
          className="w-full rounded-md lg:h-full"
          src="../../images/others/bali-200.jpg"
          alt="Aayaa Yoga School Bali"
        />
      </div>
    </div>
  );
};

export default MainPageHeader;
