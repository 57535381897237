import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Stats from "../../components/home/stats";
import MainDiveIn from "../../components/home/main-dive-in";
import { WhyBali } from "../../components/home/why-aayaa";
import { Link } from "gatsby";
import { VideoReviewsSmallBali } from "../../components/common/video-reviews-small";
import { StaticImage } from "gatsby-plugin-image";
import PageTitle from "../../components/common/page-title";
import Hero from "../../components/common/hero";

const Bali = () => {
  return (
    <Layout>
      <Seo title="Home Bali" />
      <PageTitle name="Bali" />
      <div>
        <Hero>
          <StaticImage
            className="w-full rounded-md lg:h-full"
            src="../../images/others/aayaa-school-bali.jpg"
            alt="Aayaa Yoga School Bali"
            layout="fullWidth"
          />
        </Hero>
        <Stats />
        <p className="text-base tracking-wide text-gray-700">
          Yoga started in India hundreds of years ago, but the magnetic island
          of Bali has become the region's favored yogi gateway. Bali is a
          perfect retreat for learning and practicing yoga because of its
          spiritual texture as well as its physical beauty. Yoga is about
          connecting to self, its a reunion of your Soul and the Body.
        </p>
        <p className="text-base tracking-wide text-gray-700 mt-2">
          Bali known as God’s own country makes you connect better your
          spiritual journey can never be limited to the hours of your yoga
          teacher training that you shall receive at our AAYAA center only but
          it should only enhance when you step out of className, a wave of
          spirituality.
        </p>
        <p className="text-base tracking-wide text-gray-700 mt-2">
          Balinese timeless culture in itself teaches us how to live the present
          moment, practice gratitude through the daily offerings and notice the
          genuine beauty of the surrounding nature, so every moment you are in
          Bali - you are in a state of Bliss and every minute you come closer to
          your "Interior Empires": "Mind-set, Heartset, Healthset and Soulset."
        </p>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between lg:flex-col">
          <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl mb-4">
            <div className="block text-gray-600">Courses in Bali</div>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col lg:flex-row space-y-10 lg:space-y-0">
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/bali/yttc-200"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
              >
                200 Hours YTTC
              </Link>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/bali/yttc-hatha-ashtanga-100"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
              >
                100 Hour Traditional Hatha and Ashtanga Vinyasa
              </Link>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/bali/yttc-aerial-yin-100"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
              >
                100 Hour Yin and Aerial with Acro
              </Link>
            </div>
          </div>
        </div>
        <WhyBali />
        <VideoReviewsSmallBali />
        <MainDiveIn
          destination={"/reviews#bali"}
          gallery={"/bali/gallery-permana"}
        />
      </div>
    </Layout>
  );
};

export default Bali;
