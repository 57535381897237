const yttc200 = [
  {
    dates: "15 April to 8 May 2023",
    private: "USD 1100",
    shared: "USD 1000",
  },
];

const yttc100AerialYin = [
  {
    dates: "27 March to 7 April 2022",
    private: "USD 700",
    shared: "USD 550",
  },
  {
    dates: "27 April to 8 May 2022",
    private: "USD 700",
    shared: "USD 550",
  },
];

export { yttc200, yttc100AerialYin };
