exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bali-accomodation-js": () => import("./../../../src/pages/bali/accomodation.js" /* webpackChunkName: "component---src-pages-bali-accomodation-js" */),
  "component---src-pages-bali-gallery-permana-js": () => import("./../../../src/pages/bali/gallery-permana.js" /* webpackChunkName: "component---src-pages-bali-gallery-permana-js" */),
  "component---src-pages-bali-gallery-puri-js": () => import("./../../../src/pages/bali/gallery-puri.js" /* webpackChunkName: "component---src-pages-bali-gallery-puri-js" */),
  "component---src-pages-bali-index-js": () => import("./../../../src/pages/bali/index.js" /* webpackChunkName: "component---src-pages-bali-index-js" */),
  "component---src-pages-bali-yoga-holidays-js": () => import("./../../../src/pages/bali/yoga-holidays.js" /* webpackChunkName: "component---src-pages-bali-yoga-holidays-js" */),
  "component---src-pages-bali-yttc-200-bali-puri-js": () => import("./../../../src/pages/bali/yttc-200-bali-puri.js" /* webpackChunkName: "component---src-pages-bali-yttc-200-bali-puri-js" */),
  "component---src-pages-bali-yttc-200-js": () => import("./../../../src/pages/bali/yttc-200.js" /* webpackChunkName: "component---src-pages-bali-yttc-200-js" */),
  "component---src-pages-bali-yttc-aerial-50-js": () => import("./../../../src/pages/bali/yttc-aerial-50.js" /* webpackChunkName: "component---src-pages-bali-yttc-aerial-50-js" */),
  "component---src-pages-bali-yttc-ashtanga-50-js": () => import("./../../../src/pages/bali/yttc-ashtanga-50.js" /* webpackChunkName: "component---src-pages-bali-yttc-ashtanga-50-js" */),
  "component---src-pages-bali-yttc-ashtanga-aerial-100-js": () => import("./../../../src/pages/bali/yttc-ashtanga-aerial-100.js" /* webpackChunkName: "component---src-pages-bali-yttc-ashtanga-aerial-100-js" */),
  "component---src-pages-bali-yttc-hatha-50-js": () => import("./../../../src/pages/bali/yttc-hatha-50.js" /* webpackChunkName: "component---src-pages-bali-yttc-hatha-50-js" */),
  "component---src-pages-bali-yttc-hatha-ashtanga-100-js": () => import("./../../../src/pages/bali/yttc-hatha-ashtanga-100.js" /* webpackChunkName: "component---src-pages-bali-yttc-hatha-ashtanga-100-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-calendar-bali-js": () => import("./../../../src/pages/calendar-bali.js" /* webpackChunkName: "component---src-pages-calendar-bali-js" */),
  "component---src-pages-calendar-rishikesh-js": () => import("./../../../src/pages/calendar-rishikesh.js" /* webpackChunkName: "component---src-pages-calendar-rishikesh-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rishikesh-accomodation-js": () => import("./../../../src/pages/rishikesh/accomodation.js" /* webpackChunkName: "component---src-pages-rishikesh-accomodation-js" */),
  "component---src-pages-rishikesh-gallery-js": () => import("./../../../src/pages/rishikesh/gallery.js" /* webpackChunkName: "component---src-pages-rishikesh-gallery-js" */),
  "component---src-pages-rishikesh-index-js": () => import("./../../../src/pages/rishikesh/index.js" /* webpackChunkName: "component---src-pages-rishikesh-index-js" */),
  "component---src-pages-rishikesh-yttc-200-js": () => import("./../../../src/pages/rishikesh/yttc-200.js" /* webpackChunkName: "component---src-pages-rishikesh-yttc-200-js" */),
  "component---src-pages-rishikesh-yttc-aerial-yin-100-js": () => import("./../../../src/pages/rishikesh/yttc-aerial-yin-100.js" /* webpackChunkName: "component---src-pages-rishikesh-yttc-aerial-yin-100-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

