import * as React from "react";

import YoutubeEmbed from "./youtube-embed";

const youtubeBali = ["Lg0i2axKlhs", "dsfGtIJVfts"];
const youtubeRishikesh = ["8aqa5Ax8w_8", "2o3_QOMI1oM"];

const VideoReviewsSmallBali = () => (
  <div className="pt-2">
    <p className="text-xl text-orange-600 font-semibold tracking-wide uppercase mt-8 mb-8">
      What our students have to say...
    </p>
    <div className="flex flex-col lg:flex-row lg:flex-wrap -mt-4">
      {youtubeBali.map((item) => (
        <div className="lg:w-1/2 p-4" key={item}>
          <YoutubeEmbed embedId={`${item}`} />
        </div>
      ))}
    </div>
  </div>
);

const VideoReviewsSmallRishikesh = () => (
  <div className="pt-2">
    <p className="text-xl text-orange-600 font-semibold tracking-wide uppercase mt-8 mb-8">
      What our students have to say...
    </p>
    <div className="flex flex-col lg:flex-row lg:flex-wrap -mt-4">
      {youtubeRishikesh.map((item) => (
        <div className="lg:w-1/2 p-4" key={item}>
          <YoutubeEmbed embedId={`${item}`} />
        </div>
      ))}
    </div>
  </div>
);

export { VideoReviewsSmallBali, VideoReviewsSmallRishikesh };
