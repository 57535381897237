import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ name }) => (
  <h1 className="text-3xl font-extrabold leading-9 border-b-2 border-gray-100 text-gray-900 mb-6">
    {name}
  </h1>
);

PageTitle.propTypes = {
  name: PropTypes.string,
};

export default PageTitle;
