const pricing200 = [
  {
    type: "Private AC",
    nights: 23,
    course: "200 Hours Multi Style Course Certification From Yoga Alliance",
    price: " USD 1100",
  },
  {
    type: "Twin Room AC",
    nights: 23,
    course: "200 Hours Multi Style Course Certification From Yoga Alliance",
    price: " USD 1000",
  },
];

const pricing100YinAerial = [
  {
    type: "Private AC",
    nights: 11,
    course:
      "100-Hour Yin and Aerial with Acro Yoga Teacher Course Certification",
    price: " USD 700",
  },
  {
    type: "Twin Room AC",
    nights: 11,
    course:
      "100-Hour Yin and Aerial with Acro Yoga Teacher Course Certification",
    price: " USD 550",
  },
];

export { pricing200, pricing100YinAerial };
