const pricing200 = [
  {
    type: "Private",
    nights: 21,
    course: "200 Hours Multi Style Course Certification From Yoga Alliance",
    price: " EUR 1600",
  },
  {
    type: "Twin Room",
    nights: 21,
    course: "200 Hours Multi Style Course Certification From Yoga Alliance",
    price: " EUR 1000",
  },
  {
    type: "Triple Room",
    nights: 21,
    course: "200 Hours Multi Style Course Certification From Yoga Alliance",
    price: " EUR 1200",
  },
];

const pricing200Puri = [
  {
    type: "Private",
    nights: 21,
    course:
      "200 Hours Hatha, Ashtanga, Vinyasa Course Certification From Yoga Alliance",
    price: " EUR 1500",
  },
  {
    type: "Twin Room",
    nights: 21,
    course:
      "200 Hours Hatha, Ashtanga, Vinyasa Course Certification From Yoga Alliance",
    price: " EUR 1100",
  },
];

const pricing100YinAerial = [
  {
    type: "Private",
    nights: 14,
    course: "100-Hour Ashtanga and Aerial Teacher Course Certification",
    price: " EUR 1100",
  },
  {
    type: "Twin Room",
    nights: 14,
    course: "100-Hour Ashtanga and Aerial Teacher Course Certification",
    price: " EUR 900",
  },
  {
    type: "Triple Room",
    nights: 14,
    course: "100-Hour Ashtanga and Aerial Teacher Course Certification",
    price: " EUR 800",
  },
];

const pricing100Hatha = [
  {
    type: "Private",
    nights: 7,
    course:
      "100-Hour Traditional Hatha and Ashtanga Vinyasa Yoga Teacher Training Course Certification",
    price: " EUR 1100",
  },
  {
    type: "Twin Room",
    nights: 7,
    course:
      "100-Hour Traditional Hatha and Ashtanga Vinyasa Yoga Teacher Training Course Certification",
    price: " EUR 900",
  },
  {
    type: "Triple Room",
    nights: 14,
    course: "100-Hour Ashtanga and Aerial Teacher Course Certification",
    price: " EUR 800",
  },
];

const pricing50Hatha = [
  {
    type: "Private",
    nights: 7,
    course:
      "50-Hour Traditional Hatha Yoga Teacher Training Course Certification",
    price: " EUR 800",
  },
  {
    type: "Twin Room",
    nights: 7,
    course:
      "50-Hour Traditional Hatha Yoga Teacher Training Course Certification",
    price: " EUR 600",
  },

  {
    type: "Triple Room",
    nights: 7,
    course:
      "50-Hour Traditional Hatha Yoga Teacher Training Course Certification",
    price: " EUR 500",
  },
];

const pricing50Ashtanga = [
  {
    type: "Private",
    nights: 7,
    course:
      "50-Hour Traditional Ashtanga Yoga Teacher Training Course Certification",
    price: " EUR 800",
  },
  {
    type: "Twin Room",
    nights: 7,
    course:
      "50-Hour Traditional Ashtanga Yoga Teacher Training Course Certification",
    price: " EUR 600",
  },
  {
    type: "Triple Room",
    nights: 7,
    course:
      "50-Hour Traditional Ashtanga Yoga Teacher Training Course Certification",
    price: " EUR 500",
  },
];

const pricing50Aerial = [
  {
    type: "Private",
    nights: 7,
    course:
      "50-Hour Traditional Aerial with Acro Yoga Teacher Training Course Certification",
    price: " EUR 800",
  },
  {
    type: "Twin Room",
    nights: 7,
    course:
      "50-Hour Traditional Aerial with Acro Yoga Teacher Training Course Certification",
    price: " EUR 600",
  },
  {
    type: "Triple Room",
    nights: 7,
    course:
      "50-Hour Traditional Aerial with Acro Yoga Teacher Training Course Certification",
    price: " EUR 500",
  },
];

const yogaHolidays = [
  {
    type: "Private Single",
    nights: 7,
    course: "Private room with Air conditioning",
    price: " EUR 360",
  },
  {
    type: "Private Double",
    nights: 7,
    course:
      "Yoga Holiday - Private double room with Air Conditioning, 2 people in a King bed, single room",
    price: " EUR 600",
  },
];

export {
  pricing200,
  pricing100YinAerial,
  pricing100Hatha,
  pricing50Hatha,
  pricing50Ashtanga,
  pricing50Aerial,
  yogaHolidays,
  pricing200Puri,
};
