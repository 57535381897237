import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";
import Table from "../components/common/table";

import { yttc200, yttc100AerialYin } from "../data/calendar-data-rishikesh";

const CelendarRishikesh = ({ siteTitle }) => (
  <Layout>
    <Seo title="Course Calendar Rishikesh" />
    <div>
      <PageTitle name="Course Calendar Rishikesh" />
      <Table name="200 HOUR MULTI STYLE YOGA TEACHER TRAINING" data={yttc200} />
      <Table
        name="100-HOUR YIN AND AERIAL YOGA TEACHER TRAINING"
        data={yttc100AerialYin}
      />
    </div>
  </Layout>
);

export default CelendarRishikesh;
