import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FaRegDotCircle } from "@react-icons/all-files/fa/FaRegDotCircle";

const AsthangaVinyasaYoga = () => (
  <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md ">
    <StaticImage
      className="rounded-t-lg"
      src="../../../images/course-details/ASTHANGA-VINYASA-YOGA.jpg"
      alt="ASTHANGA VINYASA YOGA"
    />

    <div className="p-5">
      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
        ASTHANGA VINYASA YOGA
      </h5>

      <ul className="list-disc list-inside mb-3 font-normal text-gray-700">
        {data.map((item) => (
          <li className="flex flex-row mb-1 text-sm" key={item.text}>
            <div className="mr-3 my-auto">
              <FaRegDotCircle className="h-3 w-3 text-orange-600" />
            </div>
            <div>{item.text}</div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const data = [
  {
    text: "Practice the ashtanga vinyasa fundamental series (K. Pattabhi Jois).",
  },
  {
    text: "Self-practice and class leadership (Mysore style).",
  },
  {
    text: "Learn how to modify the pupils' behaviour.",
  },
  {
    text: "Discover how to adjust the tough poses.",
  },
  {
    text: "Make your own vinyasa flow sequence.",
  },
  {
    text: "Learn how to do safe adjustments and corrections during Vinyasa Yoga class.",
  },
  {
    text: "KEY CONCEPTS: Drishti, Bandhas, and Ujjayi Breath.",
  },
  {
    text: "Alignment is the key: study and break down each pose to truly understand the biomechanics of the asanas.",
  },
  {
    text: "Sequencing is the art: experience and apply different strategies to make your vinyasa flow unique and creative in each of your classes.",
  },
];

export default AsthangaVinyasaYoga;
