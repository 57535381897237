import * as React from "react";

const reviewData = [
  {
    id: 1,
    name: "Luisa Praper",
    img: "https://lh3.googleusercontent.com/a/AAcHTtd6FZK8VHVG63cMvlsfiNMmWIM9VcfcHVQ9tIgz=w60-h60-p-rp-mo-br100",
    rating: "5",
    text: "I did the 200h multistyle Yoga Teacher and this experience went beyond my expectations. The teachers and the quality of teaching is great! The accommodation is in the heart of Ubud with comfortable rooms and a very nice pool. We got 3 vergetarian meals/buffets everyday. The food was outstanding. Authentic, healthy Balinese cuisine. If you want to become a yoga teacher start your journey here at Aayaa Yoga :)",
  },
  {
    id: 2,
    name: "Adrian Isoldi",
    img: "https://lh3.googleusercontent.com/a-/AD_cMMR3gLzUxPN2ODDvHhkrVgQ2v-cZCtRfbY--EsbH=w60-h60-p-rp-mo-br100",
    rating: "5",
    text: "Would highly recommend to anyone looking to do their teacher training in Ubud. Knowledgeable teachers, and make some great life long friends! Catered to my dietary requirements and made some amazing Vegan food!",
  },
];

const GoogleReviews = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-16 lg:px-8">
        <h2 className="text-center text-4xl text-gray-800 font-bold tracking-tight sm:text-5xl">
          Read trusted reviews from our students
        </h2>
        <h4 className="text-center text-base text-gray-600 tracking-tight">
          * sourced from Google reviews
        </h4>
        <div className="mt-12 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
          {reviewData.map((item) => (
            <blockquote className="rounded-lg bg-gray-100 p-8" key={item.id}>
              <div className="flex items-center gap-4">
                <img
                  alt="Man"
                  src={item.img}
                  className="h-16 w-16 rounded-full object-cover"
                />

                <div>
                  <div className="flex justify-center gap-0.5 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </div>

                  <p className="mt-1 text-lg font-medium text-gray-700">
                    {item.name}
                  </p>
                </div>
              </div>

              <p className="line-clamp-2 sm:line-clamp-none mt-4 text-gray-500">
                {item.text}
              </p>
            </blockquote>
          ))}
        </div>
        <h4 className="py-8 text-center text-xs text-gray-600 tracking-tight sm:text-xl">
          <a
            href="https://www.google.com/search?q=aayaa+yoga&oq=aayaa+yoga&aqs=chrome.0.35i39i650j46i175i199i512j0i512j0i22i30l2j69i60l2j69i61.2701j0j7&sourceid=chrome&ie=UTF-8#lrd=0x2dd23d0d3ae2a19d:0xbf6789c76af33449,1,,,,"
            target="blank"
            className="text-orange-700 ml-2 text-base"
          >
            ... read more google reviews
          </a>
        </h4>
      </div>
    </section>
  );
};

export default GoogleReviews;
