import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";
import MainDiveIn from "../../components/home/main-dive-in";

import Gallery from "@browniebroke/gatsby-image-gallery";

import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { GiForkKnifeSpoon } from "@react-icons/all-files/gi/GiForkKnifeSpoon";

const Accomodation = ({ data }) => {
  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    caption: `Aayaa Yoga Accomodation Rishikesh Gallery #${index + 1}`,
  }));

  return (
    <Layout>
      <Seo title="Accomodation & Location Rishikesh" />
      <div>
        <PageTitle name="Accomodation & Location Rishikesh" />
        <div className="mb-6">
          <Gallery images={images} />
        </div>
        <div>
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-3xl mt-12 flex">
            <FaMapMarkerAlt className="flex-none inline-block text-orange-600 mr-3" />
            Retreat Location
          </h2>

          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            Rishikesh is one of the holiest places for Hindus. That's why sages
            and saints who master the yogic arts have been visiting the city for
            thousands of years. Sacred rivers and mountains set the scene for
            yoga and reflective hikes, and rafting here is an absolute must. The
            Beatles got in touch with their Eastern spiritual side here, writing
            several songs during a 1968 stint at a local ashram.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Aayaa Yoga believes that doing yoga on a daily basis may improve
            people's physical, mental, and spiritual well-being. The resulting
            ripple effect will spread throughout your surrounding people,
            community, and workforce, positively influencing everyone.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Aayaa Yoga Teachers Training Center Rishikesh invites everyone to
            join them on the yogic path to a healthy and serene existence. They
            make certain that their location remains secure and appealing to
            you.
          </p>
          <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <p className="text-base leading-6 text-gray-600 pb-6">
              Safety features
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>
                  Staff follow all safety protocols as directed by the local
                  government.
                </li>
                <li>Hand sanitizer available in guest rooms and key areas.</li>
                <li>First aid kit available.</li>
                <li>
                  A room is available to isolate suspected or infected COVID-19
                  patients.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-3xl mt-12 flex">
            <GiForkKnifeSpoon className="flex-none inline-block text-orange-600 mr-3" />
            Food
          </h2>
          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            It is rightly said that our mind, body, and soul are shaped by the
            kind of food we have. Keeping this in mind, a great deal of care has
            been taken regarding food management. Aayaa yoga has chosen the food
            as per the requirements of the yoga practitioners.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Keeping in mind the nutritional values and following the guidelines
            of Ayurvedic science, Aayaa yoga have prepared a wide spread of
            Sattvik and Rajasik food which satisfies not only the taste of the
            tongue but also the soul and mind.
          </p>

          <p className="text-base leading-6 text-gray-600 pb-6">
            Purified drinking water, tea, and coffee available all day. We cater
            to food allergies and vegan and gluten free diet.
          </p>
          <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <p className="text-base leading-6 text-gray-600 pb-6">
              The following vegetarian meals are included:
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>Breakfast</li>
                <li>Lunch</li>
                <li>Dinner</li>
                <li>Drinks</li>
              </ul>
            </p>
            <p className="text-base leading-6 text-gray-600 pb-6">
              The following drinks are included:
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>Water</li>
                <li>Coffee</li>
                <li>Tea</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            <span className="font-semibold">Disclaimer:</span> Smoking and
            Drinking is strictly prohibited within ashram premises.
          </p>
        </div>

        <MainDiveIn
          destination={"/reviews#rishikesh"}
          gallery={"/rishikesh/gallery"}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ImagesForGalleryAccomodationRishikesh {
    images: allFile(
      filter: { relativeDirectory: { eq: "accomodation/rishikesh" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 305
              height: 210
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Accomodation;
