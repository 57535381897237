import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Stats from "../components/home/stats";
import MainPageHeader from "../components/home/main-page-header";
import MainDiveIn from "../components/home/main-dive-in";
import { WhyAayaa } from "../components/home/why-aayaa";
import Discover from "../components/home/discover";
import {
  VideoReviewsSmallBali,
  VideoReviewsSmallRishikesh,
} from "../components/common/video-reviews-small";
import OurLocations from "../components/home/location";
import GoogleReviews from "../components/home/googlereviews";
import ContactHome from "../components/home/contact-home";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div>
      <MainPageHeader />
      <Stats />
      <WhyAayaa />
      <GoogleReviews />
      <OurLocations />
      <VideoReviewsSmallBali />
      <Discover />
      <ContactHome />
      <MainDiveIn destination={"/reviews"} />
    </div>
  </Layout>
);

export default IndexPage;
