import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const schema = yup
  .object({
    name: yup
      .string()
      .trim()
      .min(3, "Must be at least 3 characters long")
      .required("Your name is required"),
    email: yup
      .string()
      .trim()
      .email("Must be a valid email")
      .required("Your email is required"),
    phone: yup.string().trim().max(15, "Phone number is too long"),
    message: yup.string().trim().required("Your message is required"),
  })
  .required();

const ContactHome = () => {
  const [state, setState] = React.useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = (data) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...data,
      }),
    })
      .then(() => navigate("/thanks/"))
      .catch((error) => alert(error));
  };

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between w-full">
      <h2 className="text-center text-4xl text-gray-700 font-bold tracking-tight sm:text-5xl pb-8">
        Contact Us
      </h2>
      <div className="bg-gray-100 relative rounded-lg p-8 sm:p-12 shadow-lg mx-auto w-full">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          action="/thanks/"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-6">
            <p className="hidden">
              <input type="hidden" name="form-name" value="contact" />
              <input name="bot-field" onChange={handleChange} />
            </p>
            <label
              htmlFor="fullname"
              className="text-body-color text-base text-gray-700"
            >
              Your Name (required)
            </label>
            <input
              {...register("name")}
              id="fullname"
              type="text"
              name="name"
              onChange={handleChange}
              maxLength={50}
              className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
            />
            <p className="text-base leading-6 text-red-600">
              {errors.name?.message}
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="text-body-color text-base text-gray-700"
            >
              Your Email (required)
            </label>
            <input
              {...register("email")}
              id="email"
              type="email"
              name="email"
              maxLength={50}
              onChange={handleChange}
              className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
            />
            <p className="text-base leading-6 text-red-600">
              {errors.email?.message}
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="mobile"
              className="text-body-color text-base text-gray-700"
            >
              Mobile
            </label>
            <input
              {...register("phone")}
              type="text"
              id="mobile"
              name="phone"
              maxLength={15}
              onChange={handleChange}
              className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
            />
            <p className="text-base leading-6 text-red-600">
              {errors.phone?.message}
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="message"
              className="text-body-color text-base text-gray-700"
            >
              Your Course (required)
            </label>
            <textarea
              rows="6"
              {...register("message")}
              id="message"
              name="message"
              maxLength={250}
              onChange={handleChange}
              className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
            ></textarea>
            <p className="text-base leading-6 text-red-600">
              {errors.message?.message}
            </p>
          </div>
          <div>
            <button
              type="submit"
              className="text-orange-700 bg-orange-300 hover:bg-orange-200 px-3 py-2 rounded-md text-base font-medium"
            >
              Enquire Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactHome;
