import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";

const Teachers = () => (
  <Layout>
    <Seo title="Teachers" />
    <div>
      <PageTitle name="Our Teachers" />
      <p className="text-base leading-6 text-gray-700 pb-6">
        Whether you want to improve your practice or start a career in Yoga, at
        AAYAA Yoga we respect your desire to learn and promise you of a highly
        gratifying course taught by the best teachers, devised by numerous yogic
        brains, and seasoned with time and experience. Our panel of teachers
        includes highly qualified yoga experts and teachers from different
        countries, of different age, from different cultural and professional
        backgrounds.
      </p>
      <p className="text-base leading-6 text-gray-700 pb-6">
        At AAYAA our belief is that everyone is unique, and we value Yog as a
        "system beyond systems" – Yoga is not about any dogma. Our major aim is
        to explain yogic practices from various perspectives – philosophical,
        scientific, cultural, spiritual… and to let our students understand and
        teach them safely and with the proper knowledge, also incorporating in
        it the ancient principles of Ayurved after all AAYAA is "All About Yoga
        And Ayurveda."
      </p>
      {teachers.map((item) => {
        return (
          <figure
            className="md:flex md:flex-row rounded-xl p-8 md:p-0 mb-12"
            key={item.id}
          >
            <div className="text-center my-auto mx-auto rounded">
              {item.id === 1 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/jagjeet-singh.jpg"
                  alt="Jagjeet Singh"
                />
              )}

              {item.id === 2 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/via-vamala.jpg"
                  alt="Via Vamala"
                />
              )}

              {item.id === 3 && (
                <StaticImage
                  className="md:w-72 md:h-64 rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/hari.webp"
                  alt="Hari"
                />
              )}

              {item.id === 4 && (
                <StaticImage
                  className="md:w-72 md:h-60 rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/obi.webp"
                  alt="Obi"
                />
              )}

              {item.id === 5 && (
                <StaticImage
                  className="md:w-72 md:h-auto rounded-md  mx-auto shadow-xl"
                  src="../images/teachers/pandu.jpg"
                  alt="Pandu"
                />
              )}

              <figcaption className="font-medium mt-4">
                <div className="text-orange-500 uppercase">{item.name}</div>
              </figcaption>
            </div>

            <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
              <blockquote>
                <p className="text-gray-700 leading-relaxed">
                  {item["about-1"]}
                </p>
                <p className="text-gray-700 leading-relaxed mt-2">
                  {item["about-2"]}
                </p>
              </blockquote>
            </div>
          </figure>
        );
      })}
    </div>
  </Layout>
);

const teachers = [
  {
    id: 1,
    name: "Jagjeet Singh",
    image: "../images/teachers/jagjeet-singh.jpg",
    "about-1":
      "Jagjeet Singh, a philosophy and pranayama specialist, was born in Haridwar and afterwards relocated to Rishikesh. He began practicing yoga when he was very young since he comes from a family of yoga enthusiasts. He grew up reading the Bhagavad Gita questions about life.",
    "about-2":
      "He learnt yoga as a youngster from his grandpa, who was a well-known yogi at the time. He received his official education from Gurukul Kangri University, where he earned an M.Sc in Yoga years in the Himalayas in pursuit of the masters. During his traveling days, he learned a great deal about yoga from a variety of gurus. He has been practicing yoga therapy, marma therapy, alternative therapy, yoga asana, pranayama, and mudra bandha as aresult of his mastery of hatha yoga.",
  },
  {
    id: 2,
    name: "VIA VAMALA",
    image: "../images/teachers/via-vamala.jpg",
    "about-1":
      "Via Vamala began doing yoga to help her physical body recover at first. Obesity, hormonal imbalances producing profound pain in the womb, digestive disorders, and sleep disorders plagued her life, bringing a great deal of uncertainty and worry. The majority of these illnesses do not manifest themselves until they have progressed to a critical stage. So she began attending yoga lessons on the spur of the moment, and each time she did, she felt a little better.",
    "about-2":
      "Until her instructor invites her to join the Ashram and learn more about yoga philosophy. As a result, she spent eight months at the Ashram. After spending time in the Ashram, she learn fresh perspective on life. She will never return to her former habits. As a result, she chose to devote her time to yoga. She promotes the well-being of our human life experience by comprehending the essence of our own existence via yoga. By allowing ourselves to be exposed to life, we may learn and grow. Life is such a wonderful experience; it is a gift that is showered onto us.",
  },
  {
    id: 3,
    name: "Hari",
    image: "../images/teachers/via-vamala.jpg",
    "about-1":
      "Hari is a meditation instructor. He practiced yoga and meditation since his childhood. His practice of Bhakti Yoga started 2007. He practiced specific meditation with japa, dharana and dhyana in Parama Dhama Ashram, as well as Bhakti yoga, Kiya yoga, Jnana yoga and Karma yoga in the ashram until 2014.",
    "about-2": "The fruit of yoga he practiced, blooms within him.",
  },
  {
    id: 4,
    name: "Obi Aggasarano",
    image: "../images/teachers/via-vamala.jpg",
    "about-1":
      "Meet Obi Aggasaraṇo, a Yin Yoga and Pranayama specialist, born in the heart of the Borneo jungle. His yoga journey began in Java, where meditation became his daily practice during his stay in a Buddhist monastery. With his background as a Hypnotherapist, his focus on psychoanalysis adds a unique dimension to his teaching style, touching the soul of his students.",
    "about-2":
      "His classes often resonate deeply, evoking tears and emotional release. Join him on an extraordinary journey to unlock your inner potential, embrace vulnerability, find healing, and embark on a transformative path.",
  },
  {
    id: 5,
    name: "Pandu",
    image: "../images/teachers/via-vamala.jpg",
    "about-1":
      "Pandu is Balinese, born and raised in Bali. He went to study in India in 2014, live in Varanasi for 5 years and then move to live in an ashram in Vrindavan for 3.5 years. He completed a 500hour Teacher Training Course at Bali Yoga School.",
    "about-2": "",
  },
];

export default Teachers;
