import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";
import Table from "../components/common/table";

import {
  yttc200 as yttc200Bali,
  yttc100hathaAshVin as yttc100hathaAshVinBali,
  yttc100AerialYin as yttc100AerialYinBali,
  yttc50Hatha as yttc50HathaBali,
  yttc50AerialAcro as yttc50AerialAcroBali,
  yttc50Ashtanga as yttc50AshtangaBali,
  yttc15days100HathaAshtanga as yttc15days100HathaAshtangaBali,
  yttc15days100AshtangaAerial as yttc15days100AshtangaAerialBali,
  yttc22days200HathaAshtangaAerialAcro as yttc22days200HathaAshtangaAerialAcroBali,
} from "../data/calendar-data-bali";

const CelendarBali = ({ siteTitle }) => (
  <Layout>
    <Seo title="Course Calendar Bali" />
    <div>
      <PageTitle name="Course Calendar Bali" />
      <Table
        name="200 Hour Multi Style Yoga Yeacher Training Course"
        data={yttc200Bali}
      />
      <Table
        name="100 Hour Traditional Hatha and Ashtanga Vinyasa Yoga Teacher Training"
        data={yttc15days100HathaAshtangaBali}
      />
      <Table
        name="100 Hour Ashtanga, Vinyasa and Aerial with Acro Yoga Teacher Training"
        data={yttc15days100AshtangaAerialBali}
      />
      <Table
        name="50 Hour Traditional Hatha & Ashtanga Yoga Teacher Training"
        data={yttc50HathaBali}
      />
      <Table
        name="50 Hour Traditional Ashtanga Vinyasa Yoga Teacher Training"
        data={yttc50AshtangaBali}
      />
      <Table
        name="50 Hour Aerial with Acro Yoga Teacher Training"
        data={yttc50AerialAcroBali}
      />
    </div>
  </Layout>
);

export default CelendarBali;
