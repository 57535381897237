import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Stats from "../../components/home/stats";
import MainDiveIn from "../../components/home/main-dive-in";
import { WhyRishikesh } from "../../components/home/why-aayaa";
import { Link } from "gatsby";
import { VideoReviewsSmallRishikesh } from "../../components/common/video-reviews-small";
import { StaticImage } from "gatsby-plugin-image";
import PageTitle from "../../components/common/page-title";
import Hero from "../../components/common/hero";

const Rishikesh = () => {
  return (
    <Layout>
      <Seo title="Home Rishikesh" />
      <PageTitle name="Rishikesh" />
      <div>
        <Hero>
          <StaticImage
            className="w-full rounded-md lg:h-full"
            src="../../images/others/aayaa-school-rishikesh.jpg"
            alt="Aayaa Yoga School Rishikesh"
            layout="fullWidth"
          />
        </Hero>
        <Stats />
        <p className="text-base tracking-wide text-gray-700">
          Rishikesh is one of the holiest places for Hindus. That's why sages
          and saints who master the yogic arts have been visiting the city for
          thousands of years. Sacred rivers and mountains set the scene for yoga
          and reflective hikes, and rafting here is an absolute must. The
          Beatles got in touch with their Eastern spiritual side here, writing
          several songs during a 1968 stint at a local ashram.
        </p>
        <p className="text-base tracking-wide text-gray-700 mt-2">
          Rishikesh timeless culture in itself teaches us how to live the
          present moment, practice gratitude through the daily offerings and
          notice the genuine beauty of the surrounding nature, so every moment
          you are in Rishikesh - you are in a state of Bliss and every minute
          you come closer to your "Interior Empires": "Mind-set, Heartset,
          Healthset and Soulset."
        </p>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between lg:flex-col">
          <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl mb-4">
            <div className="block text-gray-600">Courses in Rishikesh</div>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col lg:flex-row space-y-10 lg:space-y-0">
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/rishikesh/yttc-200"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
              >
                200 Hours YTTC
              </Link>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/rishikesh/yttc-aerial-yin-100"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
              >
                100 Hour Yin and Aerial with Acro
              </Link>
            </div>
          </div>
        </div>
        <WhyRishikesh />
        <VideoReviewsSmallRishikesh />
        <MainDiveIn
          destination={"/reviews#rishikesh"}
          gallery={"/rishikesh/gallery"}
        />
      </div>
    </Layout>
  );
};

export default Rishikesh;
