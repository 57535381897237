import React from "react";

const Hero = ({ children }) => (
  <div>
    <div
      className="flex items-center justify-center w-full mt-2 shadow-2xl hidden lg:block"
      style={{ height: "36rem" }}
    >
      {children}
    </div>
    <div className="flex items-center justify-center w-full mt-2 shadow-2xl lg:hidden">
      {children}
    </div>
  </div>
);

export default Hero;
