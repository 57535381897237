import * as React from "react";
import { Link } from "gatsby";

const OurLocations = () => {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
        <span className="block text-gray-600">We have our schools in</span>
      </h2>
      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col lg:flex-row space-y-10 lg:space-y-0">
        <div className="inline-flex rounded-md shadow">
          <Link
            to="/bali"
            className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-700 hover:bg-orange-700"
          >
            Bali
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurLocations;
