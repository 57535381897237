import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FaRegDotCircle } from "@react-icons/all-files/fa/FaRegDotCircle";

const AcroYoga = () => (
  <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md ">
    <StaticImage
      className="rounded-t-lg"
      src="../../../images/course-details/ACRO-YOGA.jpg"
      alt="ACRO YOGA"
    />

    <div className="p-5">
      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
        ACRO YOGA WORKSHOP
      </h5>

      <ul className="list-disc list-inside mb-3 font-normal text-gray-700">
        {data.map((item) => (
          <li className="flex flex-row mb-1 text-sm" key={item.text}>
            <div className="mr-3 my-auto">
              <FaRegDotCircle className="h-3 w-3 text-orange-600" />
            </div>
            <div>{item.text}</div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const data = [
  {
    text: "Learn how to trust: “As you learn to support others, you end up allowing yourself to be supported. This is a fundamental of trust: take control by letting go” (Daniel Scott).",
  },
  {
    text: "Partner yoga practice.",
  },
  {
    text: "Introduction to the basics of acroyoga: base, flyer, spotter.",
  },
  {
    text: "Proper Alignment.",
  },
  {
    text: "A Multidisciplinary Approach.",
  },
  {
    text: "Facilitators who are certified.",
  },

  {
    text: "Yoga in Various Styles.",
  },
  {
    text: "Asana Variations.",
  },
  {
    text: "Lesson Plans Based on a Theme.",
  },
  {
    text: "Yoga for beginners, movers, and flyers.",
  },
];

export default AcroYoga;
