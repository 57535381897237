import * as React from "react";

import YoutubeEmbed from "../components/common/youtube-embed";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";
import GoogleReviews from "../components/home/googlereviews";

const youtubeBali = [
  "Lg0i2axKlhs",
  "mcie2kqtBoQ",
  "EDNkGJ0tFCU",
  "RlLYXG35x2k",
  "0H76icr76Gk",
  "dMviJzKQBHw",
  "ie5lWttpFfU",
  "k9a801hvjMc",
  "Xz_SYzfEpT8",
  "dsfGtIJVfts",
  "LXD4VkTCgdc",
  "iFtgGGi6pyQ",
];

const Reviews = ({ siteTitle }) => (
  <Layout>
    <Seo title="Reviews" />
    <div className="pt-2">
      <PageTitle name="Reviews by our Students" />
      <h6
        id="bali"
        className="text-2xl font-medium leading-relaxed text-orange-600 mb-6 text-center border-b-2"
      >
        Google Reviews
      </h6>
      <div className="flex flex-col lg:flex-row lg:flex-wrap -mt-4 ">
        <GoogleReviews />
      </div>
      <h6
        id="bali"
        className="text-2xl font-medium leading-relaxed text-orange-600 mb-6 text-center border-b-2"
      >
        Bali
      </h6>
      <div className="flex flex-col lg:flex-row lg:flex-wrap -mt-4 ">
        {youtubeBali.map((item) => (
          <div className="lg:w-1/2 p-4" key={item}>
            <YoutubeEmbed embedId={`${item}`} />
          </div>
        ))}
      </div>
    </div>
  </Layout>
);

export default Reviews;
