import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";
import Stats from "../../components/home/stats";
import { PricingTableBali } from "../../components/common/pricing-table";

import Gallery from "@browniebroke/gatsby-image-gallery";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { Link } from "gatsby";
import { yogaHolidays } from "../../data/pricing-data-bali";
import { VideoReviewsSmallBali } from "../../components/common/video-reviews-small";
import Hero from "../../components/common/hero";
import { StaticImage } from "gatsby-plugin-image";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import { FaHome } from "@react-icons/all-files/fa/FaHome";

var food = [
  {
    type: "Breakfast",
    menu: [
      "Tea/Coffee/Milk",
      "Choice of Cereals",
      "Bread with butter and Jam",
      "Fresh fruits",
      "One cooked item ( pancakes /waffles / pohas / semolina / porridge / halwa )",
    ],
  },
  {
    type: "Lunch",
    menu: ["Rice", "Salad", "Vegetables", "Side dish", "Crisps"],
  },
  {
    type: "Dinner",
    menu: [
      "Rice/noodles/pasta",
      "Soup",
      "Vegetables",
      "Side Dish",
      "Dessert",
      "Crisps",
    ],
  },
];

var program = [
  {
    type: "Day 1",
    menu: [
      "2.00 p.m : Arrival and welcome drink and garland",
      "5.00 p.m : Asana practice",
      "6.00 p.m : Meditation",
      "7.00 p.m : Dinner",
      "8.00 p.m : Share stories ",
    ],
  },
  {
    type: "Day 2 to 6",
    menu: [
      "6.30 a.m : Cleansing techniques followed by pranayam and asana",
      "8.30 a.m : Breakfast ",
      "10.00 a.m : Activity - Balinese costume/ cooking class / Banten Making/Ayurveda theory/ excursion",
      "1.00 p.m : Lunch (for outdoor activity and guests who wish to explore on their own will be provided packed lunch. Afternoon will be reserved for personal activities and spa treatments or swimming activities or tarot reading sessions.",
      "5.00 p.m : Asana class followed by meditation",
      "7.00 p.m : Dinner and share stories",
    ],
  },
  {
    type: "Day 7",
    menu: [
      "6.30 a.m : Cleansing techniques followed by pranayam and asana",
      "8.30 a.m : Breakfast",
      "11.00 a.m : Departure",
    ],
  },
];

export const pageQuery = graphql`
  query ImagesForYogaHolidayGalleryBali {
    images: allFile(
      filter: { relativeDirectory: { eq: "yoga-holidays-bali" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 305
              height: 210
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const YogaHolidayBali = ({ data }) => {
  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    caption: `Aayaa Yoga Gallery #${index + 1}`,
  }));
  return (
    <Layout>
      <Seo title="Yoga Holidays Bali" />
      <div>
        <PageTitle name="7 days Immersive Yoga Holiday in Ubud, Bali" />
        <Hero>
          <StaticImage
            className="w-full rounded-md lg:h-full"
            src="../../images/yoga-holidays-bali/cover.jpeg"
            alt="Aayaa Yoga School Bali"
            layout="fullWidth"
          />
        </Hero>

        <p className="text-base leading-6 text-gray-600 pb-6 mt-6">
          We are located in a peaceful, yet very accessible location. 700 metres
          from Ubud centre nearby restaurants and shops swimming and spa is in
          house
        </p>
        <p className="text-base leading-6 text-gray-600 pb-6">
          We provide comfortable rooms with balcony, air conditioning, ensuite
          bathroom with running hot and cold water 24x7, in a serene and
          peaceful location so that you can fully immerse yourself in the lap of
          nature. Swimming pool and spa are in house. Airport transfer is
          available for a charge. One way is 30 Euros in a private car and
          available 24x7
        </p>
        <p className="text-base leading-6 text-gray-600 pb-6">
          We at Aayaa yoga take special care in preparing balanced and
          nutritious meals for our guests keeping in mind the daily requirement
          of the human body. We do not ignore the palate so that the meals are
          not repetitive and boring. Hygiene and cleanliness is a priority. We
          cater to allergies and provide Gluten free, Vegan and Lactose
          intolerenace.
        </p>
        <Stats />
        <section>
          <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600">
                Package Inclusions
              </h2>
              <p className="mb-5 font-light text-gray-500 sm:text-xl">
                Here at Aayaa we focus on providing great accomodation and
                facilities, so that you focus in your spiritual path.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h3 className="mb-4 text-2xl font-semibold text-orange-600">
                Food & Drinks
              </h3>
              <p className="mb-5 font-light text-gray-500">
                We at Aayaa yoga take special care in preparing balanced and
                nutritious meals for our guests keeping in mind the daily
                requirement of the human body. We do not ignore the palate so
                that the meals are not repetitive and boring. Hygiene and
                cleanliness is a priority. We cater to allergies and provide
                Gluten free, Vegan and Lactose intolerenace.
              </p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              {food.map((item) => {
                return (
                  <div
                    className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow"
                    key={item.type}
                  >
                    <div className="flex justify-center items-baseline my-8">
                      <span className="text-gray-500">{item.type}</span>
                    </div>

                    <ul className="mb-8 space-y-4 text-left">
                      {item.menu.map((menuItem) => {
                        return (
                          <li
                            className="flex items-start space-x-3"
                            key={menuItem}
                          >
                            <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                            <span className="text-gray-700">{menuItem}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h3 className="mb-4 text-2xl font-semibold text-orange-600">
                Program & Itinerary
              </h3>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              {program.map((item) => {
                return (
                  <div
                    className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow"
                    key={item.type}
                  >
                    <div className="flex justify-center items-baseline my-8">
                      <span className="text-gray-500">{item.type}</span>
                    </div>

                    <ul className="mb-8 space-y-4 text-left">
                      {item.menu.map((menuItem) => {
                        return (
                          <li
                            className="flex items-center space-x-3"
                            key={menuItem}
                          >
                            <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                            <span className="text-gray-700">{menuItem}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h3 className="mb-4 text-2xl font-semibold text-orange-600">
                Gallery
              </h3>
            </div>
            <div className="">
              <Gallery images={images} />
            </div>
          </div>
        </section>

        <section>
          <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h3 className="mb-4 text-2xl font-semibold text-orange-600">
                Location
              </h3>
            </div>
            <div className="">
              <span className="block uppercase text-orange-800 text-sm font-semibold mb-2">
                Contact
              </span>
              <ul className="list-unstyled">
                <li className="text-gray-700 hover:text-gray-900 font-semibold block pb-4 text-sm underline">
                  <a
                    href="https://maps.app.goo.gl/K6Eh5xo9hMtQ5inVA"
                    target="_blank"
                  >
                    <span className="flex flex-row">
                      <FaHome className="flex-none inline-block text-orange-600" />
                      <address className="flex-initial ml-2">
                        Aayaa Yoga Holidays, Puri Suksma Ubud, Bali, Indonesia
                      </address>
                    </span>
                  </a>
                </li>
                <li className="text-gray-700 hover:text-gray-900 font-semibold block pb-4 text-sm">
                  <span>
                    <FaPhone className="inline-block text-orange-600" />
                    <span className="ml-2">
                      <a href="tel:+919733181913">+91-9733181913</a>
                    </span>
                  </span>
                </li>
                <li className="text-gray-700 hover:text-gray-900 font-semibold block pb-4 text-sm">
                  <span>
                    <FaEnvelope className="inline-block text-orange-600" />
                    <span className="ml-2">
                      <a href="mailto:info@aayaayoga.in">info@aayaayoga.in</a>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <VideoReviewsSmallBali />

        <PricingTableBali pricing={yogaHolidays} yogaManual={false} />

        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block text-gray-600">
              Start your spiritual journey today.
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col lg:flex-row space-y-10 lg:space-y-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/bookings"
                className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-700 hover:bg-orange-700"
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default YogaHolidayBali;
