import React from "react";
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

const PricingTableBali = ({
  pricing,
  is200Course = false,
  yogaManual = true,
}) => (
  <section>
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600">
          Package Inclusions
        </h2>
        <p className="mb-5 font-light text-gray-500 sm:text-xl">
          Here at Aayaa we focus on providing great accomodation and facilities,
          so that you focus in your spiritual path.
        </p>
      </div>
      <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
        {pricing.map((item) => {
          return (
            <div
              className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow"
              key={item.type}
            >
              <h3 className="mb-4 text-2xl font-semibold">{item.type}</h3>
              <p className="font-light text-orange-600 sm:text-lg">
                {item.course}
              </p>
              <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-4xl font-extrabold">
                  {item.price}
                </span>
                <span className="text-gray-500">/ {item.nights} nights</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                {is200Course && (
                  <li className="flex items-center space-x-3">
                    <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                    <span className="text-gray-700">
                      Free airport pick up (drop off will be chargeable)
                    </span>
                  </li>
                )}

                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Three Sativik Meals a day
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Purified drinking water, tea & coffee:{" "}
                    <span className="font-semibold">available 24X7</span>
                  </span>
                </li>
                {is200Course && (
                  <li className="flex items-center space-x-3">
                    <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                    <span className="text-gray-700">
                      1 free excursion to Water Purification Ceremony
                    </span>
                  </li>
                )}
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">Access to swimming pool</span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span>Free WiFi</span>
                </li>
                {yogaManual && (
                  <li className="flex items-center space-x-3">
                    <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                    <span className="text-gray-700">
                      Yoga Manual, Yoga Props, Yoga Mat, Note Pad, Pen &
                      Equipment all Provided
                    </span>
                  </li>
                )}
              </ul>
              {/* <Link
                to="/bookings"
                className="shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
              >
                Enquire Now
              </Link> */}
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

const PricingTableRishikesh = ({ pricing }) => (
  <section>
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600">
          Package Inclusions
        </h2>
        <p className="mb-5 font-light text-gray-500 sm:text-xl">
          Here at Aayaa we focus on providing great accomodation and facilities,
          so that you focus in your spiritual path.
        </p>
      </div>
      <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
        {pricing.map((item) => {
          return (
            <div
              className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow"
              key={item.type}
            >
              <h3 className="mb-4 text-2xl font-semibold">{item.type}</h3>
              <p className="font-light text-orange-600 sm:text-lg">
                {item.course}
              </p>
              <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-4xl font-extrabold">
                  {item.price}
                </span>
                <span className="text-gray-500">/ {item.nights} nights</span>
              </div>

              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Shuttle pick up Dehradun Airport at Jollygrant or railway
                    station Haridwar or bus stand Rishikesh
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Three Sativik Meals a day (excluding Sundays)
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Purified drinking water, tea & coffee:{" "}
                    <span className="font-semibold">available 24X7</span>
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">1 Free excursion</span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span>Free WiFi</span>
                </li>
                <li className="flex items-center space-x-3">
                  <FaAngleRight className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="text-gray-700">
                    Yoga Manual, Yoga Props, Yoga Mat, Note Pad, Pen & Equipment
                    all Provided
                  </span>
                </li>
              </ul>
              {/* <Link
                to="/bookings"
                className="shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
              >
                Enquire Now
              </Link> */}
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

export { PricingTableBali, PricingTableRishikesh };
