import * as React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";
import Stats from "../../components/home/stats";
import { PricingTableRishikesh } from "../../components/common/pricing-table";
import Table from "../../components/common/table";
import { yttc200 } from "../../data/calendar-data-rishikesh";
import {
  yogaPhilosophy,
  anatomy,
  teachingMethodology,
  aayaTechniques,
} from "../../data/aayaa-teaching-data";
import { GrYoga } from "@react-icons/all-files/gr/GrYoga";
import MainDiveIn from "../../components/home/main-dive-in";
import AsthangaVinyasaYoga from "../../components/common/course-section/asthanga-vinyasa-yoga";
import TraditionalHathaYoga from "../../components/common/course-section/traditional-hatha-yoga";
import AerialYoga from "../../components/common/course-section/aerial-yoga";
import Meditation from "../../components/common/course-section/meditation";
import BhaktiYoga from "../../components/common/course-section/bhakti-yoga";
import AcroYoga from "../../components/common/course-section/acro-yoga";
import { pricing200 } from "../../data/pricing-data-rishikesh";
import { StaticImage } from "gatsby-plugin-image";
import { VideoReviewsSmallRishikesh } from "../../components/common/video-reviews-small";
import Hero from "../../components/common/hero";

const YTTC200 = ({ siteTitle }) => (
  <Layout>
    <Seo title="200 hours YTC" />
    <div>
      <PageTitle name="200 Hour Multi Style Yoga Yeacher Training Course" />
      <Hero>
        <StaticImage
          className="w-full rounded-md lg:h-full"
          src="../../images/others/aayaa-school-rishikesh.jpg"
          alt="Aayaa Yoga School Rishikesh"
          layout="fullWidth"
        />
      </Hero>
      <p className="text-base leading-6 text-gray-600 pb-6 mt-6">
        Our 200-hour multi-style yoga teacher training course is the ideal
        combination of theory and practice. It includes a thorough understanding
        of various yoga styles, such as Hatha Yoga, Vinyasa Yoga, Yin Yoga,
        Ashtanga Yoga, Aerial yoga, Meditation, Bhakti yoga. Aside from
        practical knowledge, you will learn about yogic texts, yogic philosophy,
        meditation concepts, various yogic techniques, as well as Anatomy,
        Physiology, and teaching methodology. We also take you on a journey of
        therapeutic aspect of yoga, so hence the Yogis who join us not only help
        themselves cure from any suffering or disease but they can be the hope
        of light for others
      </p>
      <p className="text-base leading-6 text-gray-600 pb-6">
        Since we teach a multi-style yoga practice including Hatha yoga, Vinyasa
        yoga (dynamic flow), Yin yoga, Ashtanga yoga, Aerial yoga and Acro yoga
        along with many other traditions of India. Hence this enables the Yogi
        an open platform to choose their own style of yoga practice and teaching
        in which they want to pursue the journey in future. At AAYAA we focus on
        sharing traditional yogic teachings,which designs our course on the core
        principles of Ashtanga Yoga. Ashtanga is a Sanskrit word which in
        English means eight limbs of Yoga it covers all aspects of yoga
        practices from basics to advance levels. The course at AAYAA includes
        all Yoga postures, Yamas and Niyama's, Pranayama & Shat-karma's,
        Meditation, Yoga philosophy, Yoga anatomy & physiology, Ayurveda and
        therapies, postures alignment & adjustment. We aim to ensure that when
        our students step out from our training they are equipped with a sound
        knowledge of all subjects and applications of its principles to yoga
        practice (benefits, contraindications, healthy movement patterns etc.)
        in order to bring forth inspired yoga teachers that in turn will promote
        a better tomorrow.
      </p>
      <Stats />
      <div className="">
        <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">
          19 days of instruction and training
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-4xl">
          Get your Yoga teachers certification in 22 days
        </p>
        <p className="mt-4 text-gray-500">
          At AAYAA Yoga we have created one of the most comprehensive and
          intense course since our aim is to help the Yogi's and the Yogini's
          towards a highly professional and qualified yoga teachers
          certification AAYAA Yoga Teacher Training Course is a certified course
          by Yoga Alliance which is the Certification acknowledged and valued
          worldwide enabling you the permission to teach Yoga. All courses a at
          AAYAA Yoga are devised after in-depth study of Yogic texts and by
          expert teachers who have years of experience in Yoga techniques.
        </p>
        <p className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-4xl mt-12">
          Significance of Certification
        </p>
        <p className="mt-4 text-gray-500">
          At AAYAA Our mission is to drive a deep sense of self, well-being,
          liberation, and education to all students through the embodiment of
          our ethical values. Our team consists of highly qualified trainers
          hailing from various backgrounds, each with personal and academic
          experience. Our 200 Hour Multi-Style AAYAA YOGA TEACHERS TRAINING IN
          RISHIKESH is beneficial for beginners and intermediate students, who
          are willing to teach yoga and choose yoga teaching as a career option,
          and additionally for those who wish to deepen their practice and
          understanding of yoga, or just improve their quality of life. Our
          organizers made their contributions to their life's purpose. Our 200
          Hour Multi-Style Yoga Teachers Training Course is designed to support
          in transforming you into most powerful, authentic, impactful version
          of yourself. After the completion of the course, you will be
          registered with Yoga Alliance Certification.
        </p>
      </div>
      <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0 mt-12">
        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            AAYAA Techniques, Training, and Practice
          </h3>
          <p className="my-2 text-gray-700">
            One of the most significant and necessary aspects of the training is
            Yoga teaching techniques. This section of the course comprises
            training, practising, and teaching techniques of classic Yoga
            practises such as Asana (yoga postures) according to the various
            yoga styles that we will teach in the course.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {aayaTechniques.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Anatomy and Physiology
          </h3>
          <p className="my-2 text-gray-700">
            This session will cover the following topics: general human body
            movement and terminology. The neurological, endocrine, respiratory,
            skeletal, and muscular systems, as well as their interrelationships,
            in the context of yoga practice.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {anatomy.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0 mt-12">
        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Teaching Methodology & Strategy
          </h3>
          <p className="my-2 text-gray-700">
            This module's goal is to teach you the skill of teaching as well as
            the business and marketing sides of yoga: Develop traits of a
            competent teacher such as voice tone, timing, class planning, asana
            sequencing, and the art of correction and alignment. Get the chance
            to hone your skills by teaching in small and large groups with other
            students.
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {teachingMethodology.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col ml-auto w-full">
          <h3 className="text-2xl font-medium leading-relaxed text-orange-600">
            Yoga philosophy, lifestyle & ethics
          </h3>
          <p className="my-2 text-gray-700">
            In yoga philosophy, you will discover the purpose and goals of yoga,
            as well as the ethics and practises of living a yogic lifestyle coz
            being a Yogi you need to have a understanding of
          </p>
          <ul className="text-gray-600 self-start list-inside mt-3">
            {yogaPhilosophy.map((item) => (
              <li className="flex flex-row mb-1" key={item.text}>
                <div className="mr-3">
                  <GrYoga className="h-5 w-5 text-orange-600" />
                </div>
                <div>{item.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <VideoReviewsSmallRishikesh />
      <section>
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600">
              Course Details
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <AsthangaVinyasaYoga />
            <TraditionalHathaYoga />
            <AerialYoga />
            <Meditation />
            <BhaktiYoga />
            <AcroYoga />
          </div>
        </div>
      </section>

      <PricingTableRishikesh pricing={pricing200} />
      <Table
        name="200 HOUR MULTI STYLE YOGA TEACHER TRAINING CALENDAR"
        data={yttc200}
      />
      <MainDiveIn
        destination={"/reviews#rishikesh"}
        gallery={"/rishikesh/gallery"}
      />
    </div>
  </Layout>
);

export default YTTC200;
