import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FaRegDotCircle } from "@react-icons/all-files/fa/FaRegDotCircle";

const TraditionalHathaYoga = () => (
  <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md ">
    <StaticImage
      className="rounded-t-lg"
      src="../../../images/course-details/TRADITIONAL-HATHA-YOGA.jpg"
      alt="TRADITIONAL HATHA YOGA"
      aspectRatio={4 / 3}
      layout="fullWidth"
    />

    <div className="p-5">
      <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
        TRADITIONAL HATHA YOGA
      </h5>

      <ul className="list-disc list-inside mb-3 font-normal text-gray-700">
        {data.map((item) => (
          <li className="flex flex-row mb-1 text-sm" key={item.text}>
            <div className="mr-3 my-auto">
              <FaRegDotCircle className="h-3 w-3 text-orange-600" />
            </div>
            <div>{item.text}</div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const data = [
  {
    text: "Learn asanas, the ancient Indian method.",
  },
  {
    text: "Learn about Sivananda sequence: 12 basic asanas.",
  },
  {
    text: "Practice traditional hatha yoga with the help of props.",
  },
  {
    text: "Experience restorative yoga and its therapeutic benefits.",
  },
  {
    text: "Learn the different aspects of asthanga yoga: pranayama, mudras, bhandas, kriyas…",
  },
  {
    text: "Learn about the various parts of yoga, such as pranayama, mudras, bandhas, and kriyas.",
  },
  {
    text: "Learn about Vedanta philosophy and the Bhagavad Gita, the most fundamental yogic literature.",
  },
];

export default TraditionalHathaYoga;
