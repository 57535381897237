import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PageTitle from "../../components/common/page-title";
import MainDiveIn from "../../components/home/main-dive-in";

import Gallery from "@browniebroke/gatsby-image-gallery";

import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { GiForkKnifeSpoon } from "@react-icons/all-files/gi/GiForkKnifeSpoon";

const Accomodation = ({ data }) => {
  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    caption: `Aayaa Yoga Accomodation Bali Gallery #${index + 1}`,
  }));

  return (
    <Layout>
      <Seo title="Accomodation & Location Bali" />
      <div>
        <PageTitle name="Accomodation & Location Bali" />
        <div className="mb-6">
          <Gallery images={images} />
        </div>
        <div>
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-3xl mt-12 flex">
            <FaMapMarkerAlt className="flex-none inline-block text-orange-600 mr-3" />
            Retreat Location
          </h2>

          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            This yoga teacher training takes place in Ubud, Bali. Bali is known
            as the region's favorite yogi retreat due to its spiritual and
            physical beauty, Bali is an ideal resort for learning and practicing
            yoga. Bali's breathtaking sights allow visitors to reflect and
            meditate profoundly in the fresh environment of the island. The land
            offers a diverse marine species biodiversity, as well as a multitude
            of sites to surf and explore beaches and oceans.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Aayaa Yoga believes that doing yoga on a daily basis may improve
            people's physical, mental, and spiritual well-being. The resulting
            ripple effect will spread throughout your surrounding people,
            community, and workforce, positively influencing everyone.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Aayaa Yoga Teachers Training Center Bali invites everyone to join
            them on the yogic path to a healthy and serene existence. They make
            certain that their location remains secure and appealing to you.
          </p>
          <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <p className="text-base leading-6 text-gray-600 pb-6">
              Safety features
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>
                  Staff follow all safety protocols as directed by the local
                  government.
                </li>
                <li>Hand sanitizer available in guest rooms and key areas.</li>
                <li>First aid kit available.</li>
                <li>
                  A room is available to isolate suspected or infected COVID-19
                  patients.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-orange-600 sm:text-3xl mt-12 flex">
            <GiForkKnifeSpoon className="flex-none inline-block text-orange-600 mr-3" />
            Food
          </h2>
          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            It is rightly said that our mind, body, and soul are shaped by the
            kind of food we have. Keeping this in mind, a great deal of care has
            been taken regarding food management. Aayaa yoga has chosen the food
            as per the requirements of the yoga practitioners.
          </p>
          <p className="text-base leading-6 text-gray-600 pb-6">
            Keeping in mind the nutritional values and following the guidelines
            of Ayurvedic science, Aayaa yoga have prepared a wide spread of
            Sattvik and Rajasik food which satisfies not only the taste of the
            tongue but also the soul and mind.
          </p>

          <p className="text-base leading-6 text-gray-600 pb-6">
            Purified drinking water, tea, and coffee available all day. We cater
            to food allergies and vegan and gluten free diet.
          </p>
          <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <p className="text-base leading-6 text-gray-600 pb-6">
              The following vegetarian meals are included:
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>Breakfast</li>
                <li>Lunch</li>
                <li>Dinner</li>
                <li>Drinks</li>
              </ul>
            </p>
            <p className="text-base leading-6 text-gray-600 pb-6">
              The following drinks are included:
              <ul className="list-disc list-inside mb-3 font-normal text-gray-600 mt-2">
                <li>Water</li>
                <li>Coffee</li>
                <li>Tea</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <p className="text-base leading-6 text-gray-600 pb-6 mt-4">
            <span className="font-semibold">Disclaimer:</span> Smoking and
            Drinking is strictly prohibited within ashram premises.
          </p>
        </div>

        <MainDiveIn
          destination={"/reviews#bali"}
          gallery={"/bali/gallery-permana"}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ImagesForGalleryAccomodationBali {
    images: allFile(
      filter: { relativeDirectory: { eq: "accomodation/bali" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 305
              height: 210
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Accomodation;
