import * as React from "react";
import { Link } from "gatsby";

const MainDiveIn = ({ destination, gallery }) => {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
        <span className="block">Ready to dive in?</span>
        <span className="block text-gray-600">
          Start your spiritual journey today.
        </span>
      </h2>
      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col lg:flex-row space-y-10 lg:space-y-0">
        <div className="inline-flex rounded-md shadow">
          <Link
            to="/bookings"
            className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-700 hover:bg-orange-700"
          >
            Enquire Now
          </Link>
        </div>
        <div className="ml-3 inline-flex rounded-md shadow">
          <a
            href="https://api.whatsapp.com/send?phone=+919733181913"
            target="blank"
            className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
          >
            Speak to us
          </a>
        </div>
        <div className="ml-3 inline-flex rounded-md shadow">
          <Link
            to={destination}
            className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
          >
            Reviews
          </Link>
        </div>
        {gallery && (
          <div className="ml-3 inline-flex rounded-md shadow">
            <Link
              to={gallery}
              className="w-full shadow-lg inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-orange-700 bg-white hover:bg-orange-50"
            >
              Gallery
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainDiveIn;
